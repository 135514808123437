ul{
    text-decoration: none;
    list-style: none;
}
.header{
    width: 100%;
    display: flex;
    padding: 1rem 0rem;
    align-items: center;
    justify-content: space-between;
    
}
.header .logo{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: black;
}
.right-side-header,.icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.work-space{
    background: #fbf6e4;
    height: 150px;
    width: 100%;
    margin: 2rem 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-overview{
    display: grid;
    grid-template-columns: 23% auto;
    gap: 2rem;
}
.left-menu{
    width: 100%;
    height: 550px;
    background: rgb(255, 255, 255);
    padding: 2rem 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.left-menu ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: .5rem;
}
.left-menu ul li{
    position: relative;
    font-size: 13px;
    width: 100%;
    background: #fbf6e4;
    padding: .4rem;
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.left-menu ul li.active{
    background: #fac710;
}
.left-menu ul li .total-tag{
   position: absolute;
   right: 1rem;
   background: black;
   color: #ffff;
   width: 20px;
   /* height: 20px; */
   padding: .2rem 0;
   border-radius: 50%;
   text-align: center;
   margin: 0;
}
.left-menu ul li img.icon{
    margin-right: 1rem;
}
.right-blog{
    width: 100%;
    height: auto;
}
.listing-overview{
    width: 100%;
    height: 400px;
    margin-bottom:1rem;
    background: #fbf6e4;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.create-deal-listing{
    width: 100%;
    height: 400px;
    margin-bottom:1rem;
    background: #fbf6e4;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
 
.right-blog .listing-overview .top-section{
    height: 55%;
    background: black;
    border-radius: 1rem 1rem 0 0;
    color: #ffff;
}
.right-blog .listing-overview .top-section h1{
    text-align: left;
    font-size: 2rem;
    margin-bottom: 1rem;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem 2rem;
    border-bottom: 2px solid #ffff;
}
.right-blog .listing-overview .top-section .check{
    padding: 0rem 2rem;
}
.right-blog .create-deal-listing .down-section{
    padding: 1rem 2rem;
}
.right-blog .create-deal-listing .top-section{
    height: 65%;
    background: #ffff;
    border-radius: 1rem 1rem 0 0;
    color: #000000;
}
.right-blog .create-deal-listing .top-section h1{
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem 2rem;
    border-bottom: 2px solid #ffff;
}
.right-blog .create-deal-listing .top-section .deals-tags{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
}
.right-blog .create-deal-listing .top-section .deals-tags h3{
    width: 25%;
    padding: .7rem;
    border-radius: 1rem;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
}
.right-blog .create-deal-listing .top-section .deals-tags h3.active{
    background-color: #fac710;
}
.right-blog .create-deal-listing .top-section .cta-deal{
    padding: 1rem 2rem;
}
.right-blog .create-deal-listing .top-section .cta-deal .wrapper{
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.right-blog .create-deal-listing .top-section .cta-deal .wrapper p{
   font-size: .8rem;
   font-weight: 700;
}
.right-blog .create-deal-listing .top-section .cta-deal .wrapper button{
   background-color: #fac710;
   border-radius: .5rem;
   padding: .7rem 1rem;
   border: none;
   font-weight: 700;
   cursor: pointer;
}
.right-blog .create-deal-listing .top-section .check{
    padding: 0rem 2rem;
}
.right-blog .create-deal-listing .down-section{
    padding: 1rem 2rem;
}
.process-bar{
    width: 100%;
    height: 25px;
    border-radius: 2rem;
    background: #fbf6e4;
}
.process{
    width: 65%;
    height: 25px;
    border-radius: 2rem;
    background: #fac710;
}
.listing-overview .down-section .tags-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    justify-content: flex-start;
    gap: 1rem;
}

.listing-overview .down-section .tags-container .tags{
    background: #ffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    /* padding: .3rem; */
    padding-left: 1rem;
    border-radius: 3rem;
    font-weight: 700;
    font-size: 14px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.listing-overview .down-section .tags-container .tags span{
    text-transform: capitalize;
    margin-right: 1rem;
}
.listing-overview .down-section .tags-container .tags .number{
    background: #fac710;
    height: 100%;
    text-align: center;
    min-width: 26px;
    border-radius: 50%;
    padding: .3rem;
}

.all-deal-listing{
    width: 100%;
    height: auto;
    margin-bottom:1rem;
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.right-blog .all-deal-listing .top-section h1{
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem 2rem;
    border-bottom: 2px solid #ffff;
}

.react-calendar {
    width: 257px !important;
    border-radius: 10px;
  }
  
  .calender-body {
    background-color: #e7f3f8 !important;
  }
  
  .react-calendar__tile--active {
    background:visible;
    background-color: #fbbc13 !important;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 5px 5.6667px;
    }

  .today {
    border: 2px solid #1f1b13 !important;
  }
  abbr{
    text-decoration:none;
  }
  .react-calendar_month-viewdays_day {
    margin: 12px !important;
  }
  
  .react-calendar__tile--active:enabled:hover {
    background-color: #fbbc13 !important;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #1f1b13 !important;
  }
  
  .react-calendar__tile--now {
    background-color: transparent !important;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }

  td button{
    background-color: #fac710;
    border-radius: .5rem;
    padding: .7rem 1rem;
    border: none;
    font-weight: 500;
  }
  .pagination-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
  }
  .pagination-item{
    padding: .7rem 1rem;
    border-radius: 10px;
    font-size:1rem;
    font-weight: 600;
    margin: .5rem;
  }
  .pagination-item.selected{
    background-color: #fac710;
    color: #000000;
  }
  .pagination-item.disabled{
    display: none;
    background-color: #fac710;
    color: #000000;
  }
  .pagination-item.arrow{
    cursor: pointer;
    background-color: #fac710;
    color: #000000;
  }
  /* pagination-item selected */
  .paginate{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  .paginate button{
    background-color: #fac710;
    border-radius: .5rem;
    padding: .7rem 1rem;
    border: none;
    font-weight: 500;
  }
  .paginate p {
    border-radius: .5rem;
    padding: .5rem 1rem;
    border: none;
    font-weight: 500;
    margin:.2rem;
  }
  .paginate p.active{
    background-color: #fac710;
  }

  .forms-btn button.cancel{
    border: 1px solid #000000;
    border-radius: .5rem;
    padding: .7rem 2rem;
    font-weight: 500;
  }
  .forms-btn button.save{
    background-color: #fac710;
    border-radius: .5rem;
    padding: .7rem 2rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }