@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: "Lato", sans-serif;
}
.login-page {
  /* padding: 2rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.inner-page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #fff;
}


.login-header {
  width: 65%;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  /* margin: 0 !important; */
}

.input-container {
  /* padding: 20px; */
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}

.login-button {
  background-color: #FCD95C;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
  border-radius: .5rem;
  cursor: pointer;
}
.onboard-button {
  background-color: #000000;
  border: none;
  color:#fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: .5rem;
  cursor: pointer;
}
.loading-button {
  background-color: gray;
  border: none;
  color:#fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: .5rem;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
  width: 10px;
}

input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}

.custom-checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.custom-checkbox::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 3px;
  height: 6px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.onboard-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 2px dashed #FCD95C;
  z-index: 10;
  background-color: #fff;
}

.input-container span {
  background-color: #FCD95C;
  padding: 10px;
  margin-right: 10px;
  min-width: 102px;
}

h1 {
  font-size: 3rem;
  width: auto;
  text-align: center;
}

a {
  color: #FCD95C;
}

form#login-forms {
  margin-top: 1.5rem;
  min-width:30%;
  /* background-color: #FCD95C; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  gap: .8rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
form#varify-forms{
  margin-top: 1.5rem;
  min-width:30%;
  /* background-color: #FCD95C; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  gap: .8rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.input-container input {
  border:.2px solid rgb(75, 73, 73);
  padding: .7rem;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.onboard-container span {
  font-size: 2rem;
  font-weight: bold;
}

.onboard-container button {
  font-size: 2rem;
  font-weight: bold;
  background: #111;
  color: #fff;
  padding: 15px;
  border: 1px solid #FCD95C;
  border-radius: 10px;
  cursor: pointer;
}

.recover-password {
  font-size: 14px;
  width: 100%;
  /* text-decoration: underline; */
  text-align: center;
}
