.login-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fbf6e4;
}

.inner-page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 80%;
  background-color: #fff;
}

.login-header {
  width: 400px;
  text-align: center;
}

.input-container {

}

.circle-bottom-right {
  right: 0px;
  bottom: 50px;
  position: absolute;
  width: 300px;
  z-index: 0;
}

.circle-top-left {
  left: 0px;
  position: absolute;
  width: 300px;
  z-index: 0;
}

.cute-worker-one {
  right: 0px;
  bottom: 100px;
  position: absolute;
  width: 300px;
  z-index: 0;
}

.cute-worker-two {
  left: 0px;
  top: 0;
  position: absolute;
  width: 300px;
  z-index: 0;
}

.login-button {
  background-color: #FCD95C;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}

.custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.custom-checkbox::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #FCD95C;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox-container {
  display: flex;
  width: 100%;
}

.onboard-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 2px dashed #FCD95C;
  z-index: 10;
  background-color: #fff;
}

.input-container span {
  background-color: #FCD95C;
  padding: 10px;
  margin-right: 10px;
  text-align: center;
}

h1 {
  font-size: 3rem;
  width: 500px;
  text-align: center;
}

a {
  color: #FCD95C;
}

form#login-forms {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  gap: 1rem;
}
form#varify-forms {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  gap: 1rem;
}

.input-container input {
  background: transparent;
  outline: none;
  border: none;
  font-size: 1rem;
}

.onboard-container span {
  font-size: 2rem;
  font-weight: bold;
}

.onboard-container button {
  font-size: 2rem;
  font-weight: bold;
  background: #111;
  color: #fff;
  padding: 15px;
  border: 1px solid #FCD95C;
  border-radius: 10px;
  cursor: pointer;
}